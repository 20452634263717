// Imports
          
import React, { useEffect, useMemo,useContext } from 'react';

//setContent import { useState } from 'react';

import '../../../../App.css';
//useNavigate import { useNavigate } from 'react-router-dom';


//FontAwesomeIcon import * as fa from '@fortawesome/free-solid-svg-icons';
//FontAwesomeIcon import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//API import { api } from "../../../../service/mftc-api";


function Component121Page() {
//useNavigate const navigate = useNavigate()


//setContent const [content,setContent]= useState( {} )   

//setContent const setInternal= (key,value)=>{ setContent(c=> ({...c, [key]:value}) );  }

//States


//Events


//UseEffect




//DIV
  return <><div id='bloc0'  className={ "w-full bg-pink-100 font-app-1"}     > <div id='bloc0_0'  className={ "w-full p-2 bg-white flex flex-row h-40 bg-app-color-1"}     > <div id='bloc0_0_0'  className={ "p-2 w-1/3 flex flex-row justify-center items-center"}     > <img  id='bloc0_0_0_0'  className={ "p-2 h-full   object-cover"}  src="https://fs.appisyou.com/users/3/logo_completRN1727781950214.png"   alt="undefined" /></div>
            <div id='bloc0_0_1'  className={ "p-2 w-1/3 flex flex-row items-center justify-center"}     > <img  id='bloc0_0_1_0'  className={ "p-2 h-full -mr-10   object-cover"}  src="https://fs.appisyou.com/users/3/papillon1727782385158.png"   alt="undefined" />
              <div id='bloc0_0_1_1'  className={ "p-2 flex flex-col items-center justify-center"}     > <div id='bloc0_0_1_1_0'  className={ "p-2 font-bold text-primary_color text-2xl"}     > </div>
                <div id='bloc0_0_1_1_1'  className={ "p-2 whitespace-pre-line text-secondary_color font-bold text-lg"}     > </div></div></div>
            <div id='bloc0_0_2'  className={ "p-2 w-1/3 flex flex-row justify-center items-center gap-4"}     > <img  id='bloc0_0_2_0'  className={ "w-12 h-12   object-cover"}  src="https://fs.appisyou.com/users/3/Groupe 361727782963302.png"   alt="undefined" />
              <img  id='bloc0_0_2_1'  className={ "w-12 h-12   object-cover"}  src="https://fs.appisyou.com/users/3/Groupe 371727782976689.png"   alt="undefined" />
              <img  id='bloc0_0_2_2'  className={ "w-12 h-12   object-cover"}  src="https://fs.appisyou.com/users/3/Groupe 381727783000158.png"   alt="undefined" /></div></div>
          <div id='bloc0_1'  className={ "flex flex-row items-center justify-center gap-10 text-primary_color"}     > <div id='bloc0_1_0'  className={ "p-2 font-bold"}     > </div>
            <div id='bloc0_1_1'  className={ "p-2 "}     > </div>
            <div id='bloc0_1_2'  className={ "p-2 "}     > </div>
            <div id='bloc0_1_3'  className={ "p-2 "}     > </div></div></div><div id='bloc1'  className={ "w-full p-2 bg-white flex flex-row "}     > <img  id='bloc1_0'  className={ "w-1/4 justify-center flex-grow h-full pl-40   object-cover"}  src="https://fs.appisyou.com/users/3/Fichier 58@4x1727783778765.png"   alt="undefined" />
          <div id='bloc1_1'  className={ "p-2 w-1/4 items text-secondary_color text-xl"}     > </div>
          <img  id='bloc1_2'  className={ "w-1/4 items   object-cover"}  src="https://fs.appisyou.com/users/3/lolo1727783932759.png"   alt="undefined" />
          <img  id='bloc1_3'  className={ "w-1/4 justify-center flex-grow h-full pr-40 pt-40   object-cover"}  src="https://fs.appisyou.com/users/3/Fichier 58@4x1727783778765.png"   alt="undefined" /></div></>
  
}

export default Component121Page;
